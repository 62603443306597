import {
	SITE_NAME,
	SITE_NAME2,
	SITE_URL,
	TWITTER_SITE,
} from '#app/env.remix/config'

export function generateHreflang({
	locales,
	url,
}: {
	locales: string[]
	url: string
}) {
	if (locales.length === 0) return []

	return [
		...locales.map((locale) => ({
			key: locale,
			tagName: 'link',
			rel: 'alternate',
			hrefLang: locale,
			href: `${SITE_URL}/${locale}${url}`,
		})),
		{
			key: 'x-default',
			tagName: 'link',
			rel: 'alternate',
			hrefLang: 'x-default',
			href: `${SITE_URL}${url}`,
		},
	]
}

function tagToSlug(s: string) {
	return `${s.replace(/( |-)/g, '')}`
}

export function getFacebookShareUrl({
	tag = SITE_NAME2,
	title,
	url,
}: {
	tag?: string
	title: string
	url: string
}): string {
	let searchParams = new URLSearchParams()
	searchParams.set('u', url)
	searchParams.set('quote', title)
	searchParams.set('hashtag', `#${tagToSlug(tag)}`)

	return `https://www.facebook.com/sharer/sharer.php?${searchParams.toString()}`
}

export function getTwitterShareUrl({
	tags = [],
	title,
	url,
}: {
	tags?: string[]
	title: string
	url: string
}): string {
	let searchParams = new URLSearchParams()
	searchParams.set('url', url)
	searchParams.set('text', title)
	searchParams.set(
		'hashtags',
		[...tags.map((tag) => tagToSlug(tag)), tagToSlug(SITE_NAME2)].join(','),
	)

	return `https://twitter.com/share?${searchParams.toString()}`
}

export type GetSeoMetaRequest = {
	description: string
	image: string
	keywords?: string
	ogLocale?: string
	ogType?: 'article' | 'website'
	title: string
	updatedAt: string
	url: string
}

export function getSeoMeta({
	description,
	image,
	keywords,
	ogLocale,
	ogType,
	title,
	url,
	updatedAt,
}: GetSeoMetaRequest) {
	let result = [
		{ name: 'description', content: description },
		{ property: 'og:description', content: description },
		{ property: 'og:image', content: image },
		{ property: 'og:locale', content: ogLocale || 'en_US' },
		{ property: 'og:site_name', content: SITE_NAME },
		{ property: 'og:title', content: title },
		{ property: 'og:type', content: ogType || 'website' },
		{ property: 'og:updated_time', content: `${updatedAt}T00:00:00+00:00` },
		{ property: 'og:url', content: url },
		{ title },
		{ name: 'twitter:card', content: 'summary_large_image' },
		{ name: 'twitter:creator', content: TWITTER_SITE },
		{ name: 'twitter:description', description },
		{ name: 'twitter:image', content: image },
		{ name: 'twitter:site', content: TWITTER_SITE },
		{ name: 'twitter:title', content: title },
		{ name: 'twitter:url', content: url },
	]

	if (keywords) {
		result.push({ name: 'keywords', content: keywords })
	}

	return result
}
